.popoverFeedback {
  padding: 15px;
  text-align: center;
}
.popoverFeedback button {
  font-size: 14px !important;
}
.popoverFeedback {
  width: fit-content !important;
  max-width: 250px !important;
  position: absolute;
  right: 10px;
  top: 300px;
  background: white;
  border-radius: 20px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.closeIconPopover {
  cursor: pointer;
  right: 5px;
  margin-top: -15px;
  padding: 5px;
  position: absolute;
}
.feedbackCharsLeft{
  margin-top: 8px;
    display: flex;
    justify-content: end;
    font-size: 15px;
    margin-bottom: -5px;
}
.dismissOption {
  color: gray !important;
  border: 1px solid rgb(204, 204, 204) !important;
  background: transparent;
}
#leave-feedback {
  transform: rotate(90deg);
  position: absolute;
  margin: 0;
  margin-top: 20px;
  right: -50px;
  border-radius: 0px;
  font-size: 18px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #151e62;
  color: white;
  cursor: pointer;
  z-index: 99;
}
.popoverOptions button {
  width: 100% !important;
}
.feedbackOptions button {
  font-size: 17px;
  align-items: center;
  display: flex;
  gap: 10px;
  font-weight: 600;
  background: none;
  border: none;
  color: rgb(146, 146, 146);
  cursor: pointer;
  transition: all 0.3s;
}
.feedbackOptions button:hover {
  transform: scale(1.05);
}
.no-option:hover,
.no-option-border:hover {
  color: rgb(119, 0, 0) !important;
}

.yes-option:hover,
.yes-option-border:hover {
  color: rgb(0, 119, 40) !important;
}
.no-option-border,
.yes-option-border {
  border: 1px solid rgb(187, 187, 187) !important;
  font-size: 16px !important;
}
.yes-option-border:hover {
  border: 1px solid rgb(0, 119, 40) !important;
}
.no-option-border:hover {
  border: 1px solid rgb(119, 0, 0) !important;
}

.feedbackOptions .no-selected {
  background: rgb(119, 0, 0) !important;
  border: 1px solid rgb(119, 0, 0) !important;
  color: white !important;
}
.feedbackOptions .no-selected-solid {
  color: rgb(119, 0, 0) !important;
}
.feedbackOptions .yes-selected-solid {
  color: rgb(0, 119, 40) !important;
}
.feedbackOptions .yes-selected {
  background: rgb(0, 119, 40) !important;
  border: 1px solid rgb(0, 119, 40) !important;
  color: white !important;
}
.feedbackOptions svg {
  font-size: 30px !important;
}

.feedbackCompletedMsg h1 {
  text-align: center;
  font-size: 35px;
}
.feedbackCompletedMsg h3 {
  text-align: center;
}

.rating-label-ad {
  font-size: 17px;
  margin-top: 8px;
}
.rating-label-add span {
  font-size: 15px;
  max-width: 115px;
  font-family: 'Foco CC';
  text-align: center;
  margin-top: -4px;
}
