html {
  scroll-behavior: smooth;
}
.accordianTitle {
  font-family: 'Foco CC Bd' !important;
  font-size: 22px !important;
  display: flex;
  gap: 15px;
  align-items: center;
}
.accordianTitle img {
  height: 30px;
  margin-top: -5px;
  width: 30px;
  object-fit: contain;
}
.accordianTitle svg {
  zoom: 1.2;
  margin-top: -5px;
}
.accordianPanel {
  background: #1d3c74 !important;
  color: white !important;
  margin-top: 5px !important;
  border-radius: 10px !important;
  padding-top: 5px !important;
  transition: all 0.3s;
}
.accordianPanel:hover{
  background: #002271 !important;
}
.accordianSection {
  border: none !important;
}
.accordianIcon {
  color: white !important;
  font-size: 25px !important;
}
.accordianContent > * h1 {
  font-size: 33px;
}
.accordianContent {
  padding: 20px !important;
  box-shadow: 2px 2px 5px #bdbdbd;
  border: 1px solid rgb(177, 177, 177);
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -5px;
  font-size: 20px !important;
  margin-bottom: 10px;
}
.accordianContent.mobile {
  max-height: calc(100vh - 70px);
  overflow: auto;
  position: relative;
  margin-top: 0px;
}

.petalTitle {
  height: 25px;
  width: 25px;
  border-radius: 3px 100%;
  transform: rotate(90deg);
  margin-top: 0px;
  margin-left: -2px;
  margin-top: -10px;
}

.accordianContent * > p {
  margin-top: 0px;
  margin-bottom: 0px;
}
.accordianContentHeaders {
  font-family: 'Foco CC Black';
  font-size: 28px;
}

.learnMoreBottom .subtext {
  font-family: 'Foco CC' !important;
  font-size: 19px;
}
.learnMoreHeader {
  color: #113485;
  font-family: 'Foco CC Black';
  font-size: 22px;
}

.learnMoreInfo {
  font-family: 'Foco CC' !important;
  font-size: 18px !important;
  margin-top: 10p;
}
.learnMoreInfo.question {
  font-family: 'Foco CC Bd' !important;
}
.learnMoreA {
  font-family: 'Foco CC Bd' !important;
  font-size: 19px !important;
}

.checkIcon {
  color: rgb(0, 187, 115);
  font-size: 20px;
  min-width: 20px !important;
}
.answerpoints {
  min-width: 20px !important;
}
.exclamationIcon {
  color: #c45c00;
  font-size: 20px;
  min-width: 20px !important;
}
.totalicons {
  right: 0px;
  top: -10px;
  position: absolute;
}

.answersSection {
  background: #f5faff;
  margin-left: -20px;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  padding-top: 1px;
}
.learnMoreIcons {
  border-radius: 50%;
  display: flex;
}
.learnMoreIcons img {
  padding: 10px;
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.border {
  border: 1px solid;
}
.bgRed {
  background: #ffd7d7;
}

.bgGreen {
  background: #ccfddd;
}

.bgOrange {
  background: #ffe4b3;
}

.bgGray {
  /* background: #e7e7e7; */
}
.physicalIcons {
  /* width: 35px; */
  margin-top: 0px;
  height: 35px;
}
.nutritionIcons {
  /* width: 35px; */
  margin-top: 0px;
  height: 25px;
}
.answerContainer h5 {
  margin: 0;
  font-size: 18px;
  text-align: center;
  margin-bottom: 3px;
  font-weight: 600;
}

.answerContainer {
  background: #51aeff26;
  padding: 20px 10px;
  text-align: center;
  border-radius: 10px;
  width: 170px;
  position: relative;
  padding-bottom: 10px;
}
.resultCardIcon {
  position: absolute;
  margin-top: -15px;
  left: 10px;
}
.physicalAnswer {
  width: 200px;
}
.answerContainerWeight {
  background: #51aeff26;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 17px;
  width: 210px;
}
.answerContainerWeight p {
  margin: 0;
  font-size: 20px;
  text-align: center;
  margin-bottom: 3px;
  font-weight: 500;
}
.secondaryLink {
  font-family: 'Foco CC Black' !important;
  color: #3e8fef !important;
}
.answerResponse {
  font-size: 16px !important;
}
.slashIcon {
  position: absolute;
  height: 40px;
  width: 40px;
}
.secondaryTitle {
  font-family: 'Foco CC Black' !important;
}
.secondaryColor {
  color: var(--LightBlue) !important;
}
.cc-title {
  margin: 0px !important;
  font-family: 'Foco CC Black' !important;
}
.cc-section p {
  margin: 0;
}
.cc-section li {
  font-size: 17px;
}
.slipSlopIcons {
  min-width: 140px !important;
}
/* .slipSlopSlapContainer img {
  height: 80px;
} */
.slipSlopSlapContainer h2 {
  margin-top: -10px;
  font-weight: 700;
  font-size: 28px;
  font-family: 'Foco CC Bd';
  text-align: center;
  color: #222877;
}
.slashIcon p {
  height: 1.5px;
  width: 50px;
  background: rgb(85, 85, 85);
  transform: rotate(36deg);
  margin-top: 25px;
  margin-left: 0px;
}

.uvResponseIcons {
  position: absolute;
  font-size: 17px !important;
  margin-left: -5px;
}

svg.exclamationIcon.uvResponseIcons, svg.checkIcon.uvResponseIcons {
  margin-top: 0px;
}
svg.resultCardIcon.exclamationIcon,  svg.resultCardIcon.checkIcon {
  margin-top: -10px;
}

@media screen and (max-width: 850px) {
  .answerContainer > h5{
    font-size: 14px !important;
  }
  .physicalIcons {
    height: 25px !important;
  }
  .answerContainer > p{
    font-size: 14px !important;
  }
  .exclamationIcon, .answerpoints, .checkIcon{
    font-size: 16px;
    margin-top: 6px;
  }
  .answerContainerWeight{
    zoom: 0.85;
  }
  .answerContainer h5 {
    font-size: 14px;
  }
  .learnMoreBottom .subtext {
    font-size: 14px !important;
  }
  .learnMoreHeader,
  .accordianTitle {
    font-size: 19px !important;
  }
  .learnMoreInfo {
    font-size: 15px !important;
  }
  .totalScoreAccordian {
    font-size: 19px !important;
  }
  .accordianContent > * h1 {
    font-size: 20px!important;
    margin-top: 0px !important;
    margin-bottom: 20px;
  }
  .totalScoreSection {
    zoom: 0.8;
  }
  .answersSection div{
    margin-left:-15px !important;
  }
  .learnMoreRec, .uvQuesResults, .uvQuesContainer{
    margin-left: 15px !important;
  }
  .uvQuesContainerIcons{
    margin-left:30px !important;
  }
}
