.uvChevrons {
  background: #082955;
  color: white;
  border-radius: 10px;
  font-size: 30px;
  width: 45px !important;
  padding: 3px !important;
  cursor: pointer;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.3s !important;
}
.uvChevrons:hover {
  transform: scale(1.05) !important;
  background: #0085dd !important;
}

.beachOptions img {
  height: 80px;
  object-fit: contain;
  transition: all 0.3s;
}
.beachOptions {
  background: #f0f0f0;
  padding: 10px 5px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  width: 120px;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  filter: grayscale(1);
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.3s;
  flex-grow: 1;
  flex-basis: 100px;
}

.beachOptions:hover {
  filter: initial;
  /* background: #b2e7ff; */
  cursor: pointer;
}

.beachOptions:hover img {
  transform: scale(1.08);
}
.beachBody {
  filter: grayscale(1);
  height: 400px;
  margin: 20px;
}
.beachBody_hat {
  height: 70px;
  top: -5px;
  margin-left: -5px;
  position: absolute;
}
.beachBody_shade {
  height: 200px;
  bottom: 28px;
  margin-left: -220px;
  position: absolute;
}
.beachBody_sunscreen {
  height: 100px;
  bottom: 28px;
  margin-left: 170px;
  position: absolute;
}

/* .beachBody_pants {
  height: 170px;
  width: 175px;
  top: 190px;
  position: absolute;
} */
.beachBody_shortpants {
  height: 130px;
  width: 100px;
  top: 190px;
  position: absolute;
}
.beachBodySection {
  position: relative;
  width: 100%;
  overflow: hidden;
  justify-content: center;
}

.selectedbeachanswer {
  filter: none !important;
  background: #b2e7ff !important;
}

.beachBackground {
  margin-bottom: 3px;
  max-height: 600px;
  margin-top: 30px;
  border-radius: 40px;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.062),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50px;
}
.beachWoman {
  position: absolute;
}

.beach_short_pants {
  height: 73px;
  position: absolute;
  bottom: 159px;
  margin-right: 13px;
}

.beach_long_pants {
  height: 180px;
  position: absolute;
  bottom: 52px;
  margin-right: -8px;
}
.beach_sunglasses {
  height: 13px;
  margin-top: 224px;
  position: absolute;
  margin-left: -40px;
}
.beach_sunglasses_male {
  height: 16px;
  margin-top: 199px;
  position: absolute;
  margin-left: -1px;
}
.beach_sunscreen {
  position: absolute;
  width: auto;
  margin-left: -297px;
  margin-top: 120px;
  top: 362px;
  height: 100px;
}
.beach_shade {
  position: absolute;
  width: auto;
  margin-right: 467px;
  bottom: 20px;
  height: 296px;
}
.beach_hat {
  width: 116px;
  position: absolute;
  margin-top: 187px;
  margin-left: -36px;
}
.beach_shirt_long {
  height: 158px;
  position: absolute;
  margin-top: 209px;
  margin-left: -64px;
}
.beach_shirt_short {
  height: 107px;
  position: absolute;
  margin-top: 259px;
  margin-left: -38px;
}
.beach_shirt_short_male {
  height: 148px;
  position: absolute;
  margin-top: 242px;
  margin-left: -12px;
}
.beach_long_pants_male {
  height: 176px;
  position: absolute;
  margin-top: 383px;
  margin-left: 2px;
}
.beach_hat_male {
  height: 174px;
  position: absolute;
  margin-top: 136px;
  margin-left: 1px;
}
.beach_cap_male {
  width: 60px;
  position: absolute;
  margin-top: 155px;
  margin-left: 1px;
  height: 44px;
}
.beach_shirt_long_male {
  height: 181px;
  position: absolute;
  margin-top: 242px;
  margin-left: -11px;
}
.beach_short_pants_male {
  height: 100px;
  position: absolute;
  margin-top: 382px;
  margin-left: 1px;
}
.blockIcon {
  position: absolute;
  margin-left: 31px;
  margin-top: -72px;
  color: #7b7b7b;
}
.beach_cap {
  width: 69px;
  position: absolute;
  margin-top: 190px;
  margin-left: -42px;
}

.park_shade {
  position: absolute;
  margin-right: -492px;
  top: 217px;
  height: 310px;
  width: 391px;
}
.park_sunscreen {
  position: absolute;
  width: auto;
  margin-left: -300px;
  top: 500px;
  height: 100px;
}

.park_shirt_short {
  height: 153px;
  position: absolute;
  margin-top: 242px;
  margin-left: -45px;
}

.park_dog_hat {
  position: absolute;
  height: 45px;
  margin-top: 380px;
  margin-left: 199px;
}

.park_dog_cap {
  position: absolute;
  height: 20px;
  height: 40px;
  margin-top: 381px;
  margin-left: 199px;
}

.park_cap {
  width: 52px;
  position: absolute;
  margin-top: 156px;
  margin-left: -45px;
}
.park_hat {
  width: 93px;
  position: absolute;
  margin-top: 150px;
  margin-left: -45px;
}
.park_sunglasses {
  height: 15px;
  margin-top: 192px;
  position: absolute;
  margin-left: -48px;
}

.park_dog_sunglasses {
  margin-top: 415px;
  margin-left: 199px;
  height: 17px;
  position: absolute;
}

.park_shirt_long {
  height: 165px;
  position: absolute;
  margin-top: 241px;
  margin-left: -47px;
}
.park_pants_short {
  height: 135px;
  position: absolute;
  bottom: 105px;
  margin-right: 53px;
}

.park_long_pants {
  height: 186px;
  position: absolute;
  bottom: 43px;
  margin-right: 52px;
  width: 95px;
}

.beach_seagull_sunglasses {
  height: 9px;
  margin-top: 471px;
  position: absolute;
  margin-left: 383px;
}
.beach_seagull_sunglasses_male {
  height: 9px;
  margin-top: 479px;
  position: absolute;
  margin-left: 311px;
}
.nopantsicon {
  height: 35px;
  margin-top: 5px;
  margin-bottom: 0px;
}

/* FEMALE PARK ASSETS  */
.park_shade_female {
  position: absolute;
  margin-right: -493px;
  top: 217px;
  height: 298px;
  width: 399px;
}
.park_shirt_short_female {
  height: 150px;
  position: absolute;
  margin-top: 241px;
  margin-left: -46px;
}
.park_cap_female {
  width: 56px;
  position: absolute;
  margin-top: 156px;
  margin-left: -44px;
}
.park_hat_female {
  height: 46px;
  position: absolute;
  margin-top: 157px;
  margin-left: -44px;
}
.park_sunglasses_female {
  height: 14px;
  margin-top: 194px;
  position: absolute;
  margin-left: -44px;
}
.park_shirt_long_female {
  height: 148px;
  position: absolute;
  margin-top: 241px;
  margin-left: -41px;
  width: 119px;
}
.park_pants_short_female {
  height: 131px;
  position: absolute;
  bottom: 112px;
  margin-right: 47px;
}
.park_long_pants_female {
  height: 201px;
  position: absolute;
  bottom: 42px;
  margin-right: 47px;
}
