.totalStdDrinks {
  margin-top: -25px;
  background: rgb(16, 147, 255);
  color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addminusbtns {
  background: #082955;
  color: white;
  border-radius: 10px;
  font-size: 30px;
  width: 45px !important;
  padding: 3px !important;
  cursor: pointer;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.3s !important;
  border: none;
}
.addminusbtns:hover {
  transform: scale(1.05) !important;
  background: #0085dd !important;
}
