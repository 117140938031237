.crc-info-text {
  font-size: 20px;
  line-height: 1.6;
}
.aus-state {
  fill: var(--DarkBlue);
  transition: all 0.3s;
}

.aus-state:hover {
  fill: #3a99d9;
  color: var(--DarkBlue);
  cursor: pointer;
}

.aus-state.active {
  fill: var(--Yellow);
}

.country-form {
  min-width: 300px;
  flex-wrap: wrap;
  display: flex;
}
.landingPageHeader h1 {
  color: var(--DarkBlue);
  font-size: 44px;
}
.landingPageHeader {
  z-index: 2;
  margin: 20px 5px;
}
