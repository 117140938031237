.text-value {
  font-size: 32px;
  letter-spacing: 0;
  text-align: center;
}
.subtextRef {
  font-style: italic;
  font-size: 12px !important;
  width: 90%;
}
.text-name {
  font-size: 16px;
  fill: #7d8ead;
  letter-spacing: 0;
  text-align: center;
}
.text-value1 {
  font-size: 12px;
  fill: #f31771;
  letter-spacing: 0;
  text-align: center;
  font-weight: 600;
}
.btn-small {
  font-size: 15px !important;
  box-shadow: 2px 2px 5px #d7d7d7;
  border: 1px solid #7c7c99 !important;
  color: #151e62 !important;
  font-weight: 700 !important;
  font-family: 'Foco CC' !important;
}
.apexcharts-pie-label{
  text-shadow: none !important;
  font-size: 20px !important;
  box-shadow: none !important;
  font-family: 'Foco CC Bd' !important;
}
.text-name1 {
  font-size: 12px;
  fill: #7d8ead;
  letter-spacing: 0;
  text-align: center;
}
.nutrition-subtext {
  font-size: 16px;
  margin-bottom: -10px !important;
  margin-left: 20px;
  margin-top: 10px !important;
}
.text-value2 {
  font-size: 12px;
  fill: #2cabf7;
  letter-spacing: 0;
  text-align: center;
  font-weight: 600;
}
.donationBtn {
  background: #009bdc !important;
  font-weight: 600;
}
.height50 {
  height: 50px !important;
}
.servicesContainer {
  text-align: center;
  margin-bottom: 30px;
}
.servicesContainer button {
  font-size: 15px !important;
}
.servicesContainer h1 {
  max-width: 180px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 27px !important;
  color: var(--DarkBlue);
  line-height: 1.2;
}
.donationTitle {
  color: var(--DarkBlue);
  /* max-width: 280px; */
  line-height: 1;
  font-size: 35px;
}
.cancer-atlas-iframe {
  border: none;
  border-radius: 10px;
}
.resultsHeader {
  margin-top: -30px;
  margin-left: 10px;
  margin-bottom: -8px;
}
.resultsHeader h1 {
  color: var(--DarkBlue) !important;
  font-size: 30px;
  margin-bottom: -10px;
  margin-top: 40px;
}
.resourcesHeader h1 {
  color: var(--DarkBlue) !important;
  font-size: 30px;
}
.servicesContainer img {
  width: 60px;
  object-fit: contain;
  margin: auto;
}
.research-icon{
  width: 50px ;
  height:60px ;
}
.activeBtn {
  background: var(--DarkBlue) !important;
  color: white !important;
  font-size: 15px !important;
  border-radius: 8px !important;
}
.no-scroll {
  pointer-events: none !important;
  overflow: hidden !important;
}
.chartBtn {
  transition: all 0.3s;
  font-size: 15px !important;
  border-radius: 8px !important;
}
.text-name2 {
  font-size: 12px;
  fill: #7d8ead;
  letter-spacing: 0;
  text-align: center;
}
.viewMoreHeader {
  color: var(--DarkBlue);
  font-size: 30px;
}
.cancer-atlas-link {
  font-family: 'Foco CC' !important;
  font-size: 26px !important;
  font-weight: 700 !important;
  margin-top: 20px !important;
  padding: 20px 10px 15px !important;
  border: 1px solid #57579e !important;
  box-shadow: 2px 2px 5px #d8d8d8;
  border-radius: 15px !important;
}
.atlas-external {
  font-size: 19px !important;
  margin-left: 10px;
  margin-top: -6px;
}
.downloadPdfBtn{
  padding-top: 8px !important;
  background: #009bdc !important;
  width: 220px;
  height:50px;
}
.pdfResultsLink{
  border-radius: 10px;
  box-shadow: 2px 2px 5px #cacaca;
  background: #009bdc;
  color: #fff !important;
  font-size: 19px;
  padding: 13px 13px 5px;
  transition: all .3s;
  padding-left: 35px;
}
.pdfResultsLink:hover{
  text-decoration: none !important;
  background: #0487c7;
}
.cancer-atlas-link a {
  text-decoration: none;
  font-size: 28px;
  line-height: 1;
  font-weight: 700;
  font-family: 'Foco CC Bd';
  color: var(--DarkBlueHover) !important;
}
.status-value {
  font-size: 28px;
  font-weight: bold;
}
.status-name {
  font-size: 16px;
}

.totalScoreLabel {
  font-size: 27px;
}

.totalscores-radial-track {
  fill: red !important;
}
.nutrition-res-container > * .apexcharts-legend-text{
  font-size: 14px !important;
}

.totalScoreSection {
  border: 1px solid #e9e9e9;
  box-shadow: 2px 2px 5px #b1b1b1;
  border-radius: 20px;
  width: 150px;
  padding: 20px 15px;
  text-align: center;
  justify-content: center;
  transition: all 0.3s;
  flex-grow: 1;
  flex-basis: 100px !important;
  min-width:150px !important;
}
/* .totalScoreSection:hover {
  background: #fffef3;s
  cursor: pointer;
} */
.totalScoreChart {
  height: 180px !important;
}
.fontWeightBold {
  font-weight: 600 !important;
}
.italic {
  font-style: italic;
}
.smallText {
  font-size: 15px;
}
.screening-img{
  padding:10px;
  height: 70px;
  align-items: center;
  display: flex;
  /* justify-content: center; */
}
.screening-img img{
  width: 160px;
}
.screening-programs{
  /* background: #d93671; */
  /* box-shadow: 2px 2px 5px rgb(218, 218, 218); */
  border-radius:20px;
  width: fit-content;
  /* padding: 10px; */
  transition: all 0.4s;
  margin-bottom:10px;
  align-items: center;
  display:flex;
  gap:10px;
}
/* .screening-programs:hover{
  box-shadow: 2px 2px 5px rgb(174, 207, 238);
  cursor:pointer;
} */
.screening-programs:hover > * .arrow{
  color: var(--DarkBlueHover);
  margin-left:-40px;
}
.screening-programs > * .arrow{
  font-size:33px;
  margin-right:10px;
  color: var(--DarkBlue);
  transition: all 0.4s;
  position: absolute;
  margin-left:-60px;
  margin-top:10px;
  display:none;
}
.screening-programs p{
 line-height: 1.3;
 font-size:16px;
}
.screening-title{
  display:none;
 }

@media screen and (max-width: 850px) {

  .cancer-atlas-link{
    font-size:20px  !important;
  }
  .screening-title{
    display:block;
   }
   .number-screening{
    display:none;
   }
.screening-programs{
  flex-wrap:wrap;
}
.screening-programs > * .arrow{
 display:none;
}
.screening-programs p{
 margin:0px;
 margin-left:10px;
 }
 .screening-programs{
  padding: 5px;
  gap: 0px;
  box-shadow: none;
  margin-left: -15px;
    margin-top: -5px;
 }
 .screening-img img{
  display:none;
 }
 .screening-img{
  height:0;
  padding: 0px;
 }
 .screening-programs:hover{
  box-shadow:none;
  cursor:auto;
 }
}


.learnMoreBtn {
  color: gray !important;
  border: 1px solid rgb(235, 235, 235) !important;
  background: transparent;
  box-shadow: 2px 2px 5px rgb(236, 236, 236);
  font-size: 12px !important;
  margin-top: 10px !important;
  transition: all 0.3s;
}
.learnMoreBtn.uv:hover {
  background: rgb(228, 194, 0);
  border: 1px solid rgb(228, 194, 0) !important;
  color: white !important;
}
.learnMoreBtn.smoking:hover {
  background: #878888;
  border: 1px solid #878888;
  color: white !important;
}
.learnMoreBtn.alcohol:hover {
  background: #f05029;
  border: 1px solid #f05029;
  color: white !important;
}
.learnMoreBtn.nutrition:hover {
  background: #73c04b;
  border: 1px solid #73c04b;
  color: white !important;
}
.learnMoreBtn.physical:hover {
  background: #199bd7;
  border: 1px solid #199bd7;
  color: white !important;
}
.learnMoreBtn.screening:hover {
  background: #7d489c;
  border: 1px solid #7d489c !important;
  color: white !important;
}
.score-chevron{
    font-size: 26px;
    margin-bottom: -8px;
}
.increase-decrease-scores{
  font-size: 16px;
  text-align: center;
  margin-top: -18px;
  margin-right: 0px;
  margin-bottom: 2px;
  margin-left: 0px;
  height: 18px;
}
.increase-color{
  color: #007f34;
}
.decrease-color{
  color: #c45c00;
}

.totalScoreSection.add {
  margin-top: 60px;
}

.totalScorePetal {
  height: 20px;
  width: 20px;
  border-radius: 3px 100%;
  transform: rotate(90deg);
  margin-top: 0px;
  min-height: 20px;
  min-width: 20px;
}
.totalScoreLabels {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: -5px;
  margin-top: -30px;
  font-family: 'Foco CC Black';
}
.totalScore100 {
  margin-top: 75px;
  z-index: 3;
  margin-bottom: -113px;
  font-size: 20px;
  position: sticky;
  text-align: center;
  width: 100%;
  font-family: 'Foco CC Bd';
}
.totalScoreLabels h3 {
  font-size: 20px;
}
.tooltip_stacked_custom {
  color: red !important;
}
.tooltip_stacked_custom {
  padding: 5px;
  font-family: 'Foco CC';
}
.tooltip_stacked_custom h4 {
  font-size: 25px !important;
}

/* Screens smaller than 850px */
@media screen and (max-width: 850px) {
  .petalTitle {
    display: none !important;
  }
  .cancer-atlas-link a,
  .status-value {
    font-size: 22px;
  }
  .servicesContainer img {
    width: 45px;
  }
  .research-icon{
    height:45px ;
  }
  .servicesContainer h1 {
    font-size: 18px !important;
    max-width: 110px !important;
  }
}

@media screen and (max-width: 400px) {
.servicesContainer{
  zoom:0.8
}
.donationSubtext{
  font-size:13px;
}
.donationTitle{
  font-size:16px !important;
}
.donation-logo{
  max-height: 60px !important
}
.servicesBtns button{
  zoom:0.8
}
}