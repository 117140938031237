/* Animated bubble background */

.container-wave {
  background: -moz-linear-gradient(
    45deg,
    #cff1ff 0%,
    #b4e9ff 29%,
    #84dbff 66%,
    #70d4ff 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #cff1ff 0%,
    #b4e9ff 29%,
    #84dbff 66%,
    #70d4ff 100%
  );
  background: linear-gradient(
    45deg,
    #b8eaff 0%,
    #b4e9ff 29%,
    #84dbff 66%,
    #70d4ff 100%
  );
  -webkit-animation: Gradient 35s ease infinite;
  -moz-animation: Gradient 35s ease infinite;
  animation: Gradient 35s ease infinite;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
}

.bg-blob {
  background: white;
  height: 500px;
  width: 500px;
  border-radius: 30% 50% 20% 40%;
  animation:
    transform 20s ease-in-out infinite both,
    movement_one 80s ease infinite both;
  opacity: 0.3;
  position: absolute;
  left: -10%;
  top: -20%;
}
.bg-blob.one {
  height: 500px;
  width: 500px;
  left: -100px;
  top: 0px;
  transform: rotate(-180deg);
}

.bg-blob.two {
  height: 950px;
  width: 950px;
  left: 500px;
  top: 0px;
  transform: rotate(-180deg);
  animation:
    transform 20s ease-in-out infinite both,
    movement_two 60s ease infinite both;
}

@keyframes transform {
  0%,
  100% {
    border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
  }
  20% {
    border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%;
  }
  40% {
    border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%;
  }
  60% {
    border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%;
  }
  80% {
    border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%;
  }
}
@keyframes movement_one {
  0%,
  100% {
    transform: translate(100%, 200%);
  }
  50% {
    transform: translate(300%, 0%) rotateY(10deg);
  }
}

@keyframes movement_two {
  0%,
  100% {
    transform: translate(100%, 80%);
  }
  50% {
    transform: translate(-40%, -50%) rotateY(10deg);
  }
}
