.app-header {
  background: rgb(255 255 255 / 0.91);
  color: var(--DarkBlue);
  width: 100%;
  height: 95px;
  display: flex;
  gap: 20px;
  align-items: center;
  font-family: 'Foco CC Black';
  font-size: 16px;
  line-height: 1;
  margin-bottom: auto;
  z-index: 3;
  justify-content: space-between;
  width: 95%;
  margin: auto;
}
.app-header .navbar-logo {
  display: flex;
  gap: 20px;
}

.yellow-fullstop {
  font-size: 40px;
  color: var(--Yellow);
}
.ccq-logo {
  height: 90px;
  margin-top: 5px;
}
.headerBtns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-weight: 800;
}
.headerBtns .secondaryBtn {
  color: white;
  border: 2px solid #009bdc;
}
.headerBtns .callus {
  color: white;
}
.mobileLogo {
  height: 60px;
  display: none;
}
.header-blob {
  display: flex;
  justify-content: center;
}
.header-petal {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.header-blob svg,
.header-petal svg {
  transition: fill 0.3s ease;
  height: 70px;
  margin-top: 8px;
}
.icon-navbar {
  max-height: 40px;
  position: absolute;
  margin-top: 13px;
  transition: all 0.3s;
  animation: load-blob 0.5s ease-in-out;
}
.icon-navbar-petal {
  position: absolute;
  margin-left: 3px;
  margin-top: 18px;
  transition: all 0.3s;
  animation: load-blob 0.5s ease-in-out;
}
.icon-navbar-petal.med {
  height: 34px;
  margin-top: 16px;
}

.icon-navbar-petal.lg {
  height: 36px;
  margin-top: 17px;
}

.icon-navbar-petal.sm {
  height: 28px;
  margin-top: 20px;
}

@keyframes load-blob {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-in {
  opacity: 1 !important;
  transform: scale(1) !important;
  animation: load-blob 1s ease-in-out;
}

.fade-out {
  opacity: 0 !important;
  transform: scale(0) !important;
  animation: load-blob 1s ease-in-out;
}
.navbar-header {
  max-width: 250px;
  margin-top: 30px;
  margin-left: -10px;
  cursor:pointer;
}
@media screen and (max-width: 850px) {
  
.app-header {
  height: 70px !important;
}

  .header-blob svg {
    height: 40px;
  }
  .header-petal svg {
    height: 50px;
  }
  .icon-navbar {
    max-height: 20px;
    margin-top: 10px;
  }

  .icon-navbar-petal.med {
    height: 25px !important;
    margin-top: 14px !important;
  }

  .icon-navbar-petal.lg {
    height: 25px !important;
    margin-top: 14px;
  }

  .icon-navbar-petal.sm {
    height: 20px !important;
    margin-top: 16px;
  }

  .navbar-header {
    font-size: 25px !important;
    line-height: 0.8 !important;
  }

  
}

@media screen and (max-width: 350px) {
  .ccq-logo {
    display: none !important;
  }
}
