.nutritionSliders {
  max-width: 280px !important;
  color: var(--LightBlue) !important;
}

.nutritionSection {
  border-radius: 15px;
  padding: 0px 25px;
  box-shadow: 2px 2px 5px #d3d3d3;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border: 1px solid #7ec9f3;
}

.sliderSubtext img {
  height: 25px;
}
