.facebookShare {
  background: #3062f1 !important;
}
.twitterShare {
  /* background: #499ded !important; */
  background: #1f1f1f !important;
}
.whatsappShare {
  background: #2f9623 !important;
}
.linkedinShare {
  background: #326ac1 !important;
}
.googleShare {
  background: #cc5345 !important;
}

.socialShare {
  height: 50px;
  border-radius: 50% !important;
  width: 50px !important;
  padding: 5px !important;
  min-width: auto !important;
  box-shadow: 2px 2px 5px #c1c1c1;
  border: 1px solid rgb(212, 236, 255) !important;
  transition: all 0.2s !important;
}
.socialShare a {
  font-size: 23px !important;
}
.facebookShare:hover,
.twitterShare:hover,
.whatsappShare:hover,
.copylinkShare:hover,
.linkedinShare:hover,
.googleShare:hover {
  transform: scale(1.1);
}
.googleShare a,
.twitterShare a,
.whatsappShare a,
.linkedinShare a,
.facebookShare a {
  color: white !important;
}
.snackbar div {
  color: #1c1c1c !important;
  background: #ffffff !important;
  font-weight: 600;
}

.socialMediaContainer {
  display: flex !important;
  gap: 10px !important;
  justify-content: center;
  margin: 20px 0px 10px 0px;
}

.socialShareHeader {
  width: 100%;
  margin-bottom: 0px;
  text-align: center;
  font-size: 22px;
  font-family: 'Foco CC';
  font-weight: 600;
  color: #242529;
}
