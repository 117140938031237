.events-container{
    background: white;
    padding: 10px 10px 30px 10px;
    border-radius: 15px;
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin: 30px auto 40px auto !important;
    width: 95% !important;
    height: calc(100% - 500px);
    font-family: 'Foco CC';
    font-size: 17px;
    z-index: 10;
    max-width: 2000px !important;
    min-height: 1500px;
}

.pass-container{
    background: white;
    padding: 20px;
    padding-bottom:40px;
    border-radius: 15px;
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin: 30px auto 40px auto !important;
    width: 95% !important;
    font-family: 'Foco CC';
    font-size: 17px;
    z-index: 10;
}
.enter-code {
    margin: 10px auto 0px auto !important;
    width: 95% !important;
    font-family: 'Foco CC';
}
.totalScore100_events {
    margin-top: 85px;
    z-index: 3;
    margin-bottom: -130px;
    font-size: 22px;
    position: sticky;
    text-align: center;
    width: 100%;
    font-family: 'Foco CC Bd';
  }
  .custom-tooltip{
    font-size:17px;
    line-height: 1.3;
  }
  
  .custom-tooltip p{
    margin:0; 
  }

.paper-container{
    border: 1px solid #e9e9e9;
    box-shadow: 2px 2px 5px #b9b9b9;
    border-radius: 20px;
    font-size: 35px;
    margin: 10px;
    margin-top: 20px;
    padding: 15px;
    width: fit-content
}
.event-total{
    color: #474747 !important;
    font-size: 30px !important;
    font-family: 'Foco CC Black';
    border: 1px solid #e9e9e9;
    box-shadow: 2px 2px 5px rgb(216, 216, 216);
    border-radius: 20px;
    padding: 7px 15px;
    padding-top:12px;
}
.event-spantotal{
    display: flex;
    width: fit-content;
    padding: 8px;
    margin-left: auto;
    padding-top: 12px;
    margin-top: -30px;
    border: 1px solid #e9e9e9;
    border-radius: 20px;
    box-shadow: 2px 2px 5px #d8d8d8;
    font-size: 25px !important;
    padding: 15px 10px 8px 10px;
}
.results-header{
    color: #474747 !important;
    font-size: 35px !important;
    font-family: 'Foco CC Black';
}
.results-question{
    margin-bottom: -10px;
    font-size: 27px;
    color: #00026e;
    margin-top: 10px;
    height: 70px;
    font-weight:700;
    font-family: 'Foco CC' !important;
}
.event-tab{
    box-shadow:none;
    font-size:25px !important;
    font-family: 'Foco CC Black' !important;
}
.change-view-container{
    margin: 30px 20px 0px 0px
}

.change-view-container button{
    padding: 10px 20px;
    border-radius: 40px;
    padding-top:14px;
    border:none;
    font-family: 'Foco CC Bd';
    font-size:20px !important;
    color: rgb(107, 107, 107);
    box-shadow: 2px 2px 5px rgb(216, 216, 216);
    transition: all 0.4s;
    cursor:pointer;
}
.change-view-container button.active{
    background: #1a1e60;
    color: white;
}
.datetime-input{
    font-family: 'Foco CC';
    padding-top: 20px;
    border: 1px solid gray;
    border: none;
    border-bottom: 1px solid gray !important;
    font-size: 18px;
    margin-right: 10px;
    cursor:pointer;
}
.datetime-input:active,.datetime-input:focus,.datetime-input:focus-visible{
    border: none !important;
    border-bottom: 1px solid rgb(155, 155, 155) !important;
    outline: none;
}
#filled-basic{
    font-size:18px;
    width: 150px;
}
.nutrition-res-container{
    border: 1px solid #7ec9f3;
    border-radius: 15px;
    box-shadow: 2px 2px 5px #d3d3d3;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 0 25px;
}
.nutrition-res-header{
    text-align: center;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: normal;
    font-size: 26px;
    margin-top:25px;
    font-family: 'Foco CC' !important;
}

.nutrition-main-ques{
    margin-top: 40px;
    margin-bottom: -10px;
    color: #161616;
    font-size: 26px;
    font-family: 'Foco CC' !important;
}

.badge {
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    box-shadow: 2px 2px 5px #ededed;
    padding: 10px;
    width: 250px;
    text-align: center;
    position: relative;
}
.badge-place {
    align-content: center;
    align-items: center;
    background: #3d99f1;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 31px;
    height: 36px;
    justify-content: center;
    margin: auto;
    padding-top: 8px;
    position: absolute;
    top: 5px;
    width: 45px;
}

.badge-title{
    font-size: 27px;
}
.badge-image{
    height: 125px;
    margin-top: 28px;
}