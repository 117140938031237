.lifestyle6-section {
  min-width: 158px;
  max-width: fit-content;
  margin-top: -15px;
  font-family: 'Foco CC Bd';
  font-size: 19px;
}
.lifestyle6-section h2 span {
  font-family: 'Foco CC Black';
  font-size: 33px;
}
.lifestyle6-section h2 {
  color: var(--DarkBlue);
  white-space: nowrap;
  font-size: 28.5px !important;
}
.lifestyle-petals {
  position: relative;
}
.petal {
  height: 17px;
  width: 17px;
  border-radius: 2px 100%;
  position: absolute;
  right: 20px;
  opacity: 0;
  background: #d1d1d1;
}

.headerPetal {
  height: 37px;
  width: 35px;
  border-radius: 4px 100%;
  transform: rotate(90deg);
  margin-top: -11px;
}
.headerPetal.yellow {
  background: var(--L6Yellow);
}

.petal.one {
  top: -90px;
  rotate: 45deg;
  right: 5px;
  animation: spin 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.petal.two {
  top: -81px;
  rotate: 86deg;
  right: -14px;
  animation: spin2 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.petal.three {
  top: -66px;
  rotate: 113deg;
  right: -21px;
  animation: spin3 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.petal.four {
  top: -49px;
  rotate: 154deg;
  right: -22px;
  animation: spin4 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.petal.five {
  top: -34px;
  rotate: 183deg;
  right: -13px;
  animation: spin5 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.petal.six {
  top: -29px;
  rotate: 45deg;
  right: 4px;
  animation: spin6 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.petal.blue.active {
  background: var(--L6Blue);
}
.petal.green.active {
  background: var(--L6Green);
}

.petal.purple.active {
  background: var(--L6Purple);
}

.petal.red.active {
  background: var(--L6Red);
}

.petal.yellow.active {
  background: var(--L6Yellow);
}
.petal.gray.active {
  background: var(--L6Gray);
}
.lifestyleheaderh2 p {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Foco CC';
  position: absolute;
  margin-top: -10px;
  margin-left: 14px;
}

@keyframes spin {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg) scale(0.01);
    transform: rotate(0deg) scale(0.01);
  }
  100% {
    opacity: 100;
    -webkit-transform: rotate(720deg) scale(1);
    transform: rotate(720deg) scale(1);
  }
}

@keyframes spin2 {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg) scale(0.01);
    transform: rotate(0deg) scale(0.01);
  }
  100% {
    opacity: 100;
    -webkit-transform: rotate(720deg) scale(1);
    transform: rotate(720deg) scale(1);
  }
}

@keyframes spin3 {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg) scale(0.01);
    transform: rotate(0deg) scale(0.01);
  }
  100% {
    opacity: 100;
    -webkit-transform: rotate(720deg) scale(1);
    transform: rotate(720deg) scale(1);
  }
}

@keyframes spin4 {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg) scale(0.01);
    transform: rotate(0deg) scale(0.01);
  }
  100% {
    opacity: 100;
    -webkit-transform: rotate(720deg) scale(1);
    transform: rotate(720deg) scale(1);
  }
}

@keyframes spin5 {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg) scale(0.01);
    transform: rotate(0deg) scale(0.01);
  }
  100% {
    opacity: 100;
    -webkit-transform: rotate(720deg) scale(1);
    transform: rotate(720deg) scale(1);
  }
}

@keyframes spin6 {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg) scale(0.01);
    transform: rotate(0deg) scale(0.01);
  }
  100% {
    opacity: 100;
    -webkit-transform: rotate(720deg) scale(1);
    transform: rotate(720deg) scale(1);
  }
}
