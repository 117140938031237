:root {
  --LightBlue: #1e90ff;
  --DarkBlue: #00026e;
  --DarkBlueHover: #0003a4;
  --White: #ffffff;
  --Yellow: rgb(255, 191, 0);
  --L6Blue: #199bd7;
  --L6Green: #73c04b;
  --L6Purple: #7d489c;
  --L6Red: #f05029;
  --L6Yellow: #fcd207;
  --L6Gray: #878888;
}
@font-face {
  font-family: 'Foco CC';
  src: url('./assets/fonts/FocoCC_Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'Foco CC Lt';
  src: url('./assets/fonts/FocoCC_Lt.ttf') format('truetype');
}

@font-face {
  font-family: 'Foco CC Bd';
  src: url('./assets/fonts/FocoCC_Bd.ttf') format('truetype');
}

@font-face {
  font-family: 'Foco CC Black';
  src: url('./assets/fonts/FocoCC_Blk.ttf') format('truetype');
}
.MuiFormControlLabel-asterisk {
  color: transparent !important;
}
h1 {
  font-family: 'Foco CC Black' !important;
}
.agbalumo {
  font-family: 'Agbalumo' !important;
}
.disabledView {
  pointer-events: none;
  opacity: 0.5;
}
.highlighted-text {
  font-family: 'Foco CC Bd';
  color: var(--DarkBlue);
}
.highlighted-text-header {
  font-family: 'Foco CC Bd';
  font-weight: 600;
  color: var(--DarkBlue);
  font-size: 20px;
}
.font-synthesis-none {
  font-synthesis: none !important;
}
body {
  margin: 0;
  font-family: 'Foco CC', 'Roboto', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.headerBlack {
  font-family: 'Foco CC Black';
}
.text-white {
  color: var(--White);
}
.crc-header {
  font-size: 36px !important;
  margin-top: 40px;
  color: var(--DarkBlue);
  max-width: 220px;
  line-height: 1.1;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button {
  font-size: 18px !important;
  text-transform: none !important;
}
.borderRadius8 {
  border-radius: 8px !important;
}
.parent-container {
  background: white;
  padding: 10px 10px 30px 10px;
  border-radius: 15px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  margin: 30px auto 40px auto !important;
  width: 95% !important;
  height: calc(100% - 500px);
  font-family: 'Foco CC';
  font-size: 17px;
  z-index: 10;
}
.dflex {
  display: flex !important;
  gap: 20px;
}
.gap10 {
  gap: 10px !important;
}
.gap15 {
  gap: 15px !important;
}
.alignitemsstart {
  align-items: start;
}
.alignitemscenter {
  align-items: center;
}
.alignitemsnormal {
  align-items: normal;
}
.flexwrap {
  flex-wrap: wrap;
}
.flex1 {
  flex: 1 !important;
}
.justifycontentcenter {
  justify-content: center;
}
.justifycontentspacebetween {
  justify-content: space-between;
}
.justifycontentspaceevenly {
  justify-content: space-evenly;
}
.error-text {
  color: red;
}
.justifycontentend {
  justify-content: end;
}
.justifycontentspacearound {
  justify-content: space-around;
}
.m30 {
  margin: 30px !important;
}
.mauto {
  margin: auto !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr-auto {
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.ml0 {
  margin-left: 0 !important;
}
.p5 {
  padding: 5px !important;
}
.pl5 {
  padding-left: 5px !important;
}
.pr5 {
  padding-right: 5px !important;
}
.nextBtn {
  font-size: 20px !important;
  font-weight: 500 !important;
  align-items: center !important;
  font-family: 'Foco CC' !important;
  padding: 4px 30px !important;
  width: 100px !important;
  background-color: #082955 !important;
  margin-left: auto !important;
  padding-top: 10px !important;
  border-radius: 8px !important;
}
.nextBtn:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.prevBtn {
  border: 1px solid rgb(179, 179, 179) !important;
  background: transparent !important;
  color: rgb(150, 150, 150) !important;
  box-shadow: none !important;
  margin-right: auto !important;
  border-radius: 8px !important;
}
.prevBtn:hover,
.skipBtn:hover {
  color: gray !important;
  border: 1px solid gray !important;
}
.skipBtn {
  border: 1px solid rgb(179, 179, 179) !important;
  background: transparent !important;
  color: rgb(150, 150, 150) !important;
  box-shadow: none !important;
}
.Mui-selected {
  background-color: #fff !important;
  box-shadow: 2px 2px 5px gray;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  padding-top: 20px !important;
}
.dropdown-selects > * .Mui-selected {
  box-shadow: none;
}

.tabs-res-container {
  background-color: #f8f8f8;
  margin-left: -24px;
  margin-top: -20px;
  max-height: 60px;
  width: calc(100% + 48px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.submitForm {
  background: rgb(3 155 255) !important;
  font-weight: 700 !important;
  font-family: 'Foco CC Bd' !important;
  width: 100% !important;
  text-align: center !important;
  margin-top: 35px !important;
  height: 60px !important;
  margin-bottom: 40px !important;
}
.f25 {
  font-size: 25px !important;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.link {
  font-family: 'Foco CC Bd' !important;
  color: #4d8ecd !important;
  text-decoration: none !important;
}
.f30 {
  font-size: 30px !important;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}
.m10 {
  margin: 10px !important;
}
.m5 {
  margin: 5px !important;
}
.MuiMenuItem-root {
  box-shadow: none;
  font-size: 15px !important;
}
.modalStyles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  padding-top: 10px;
  border-radius: 15px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  outline: none !important;
  max-height: 98vh;
  overflow: auto !important;
}

.answer-btn {
  font-size: 16px !important;
  border: none !important;
  background: #082955 !important;
  color: white !important;
  border-radius: 8px !important;
  text-align: left;
}
.answer-btn:hover {
  transition: all 0.2s;
  background: #0085dd !important;
  transform: scale(1.03);
}
.selected-answer {
  background: #0085dd !important;
}
.w100 {
  width: 100% !important;
}
.w80 {
  width: 80% !important;
}
.optionaltext {
  font-size: 14px;
  color: rgb(77, 77, 77);
}
.border-table td {
  border-top: 1px solid gray;
}
.height100 {
  min-height: 100% !important;
}
.height0 {
  max-height: 0 !important;
  height: 0 !important;
}
.margin0 {
  margin: 0 !important;
}

.autofillBtn {
  font-size: 15px !important;
}

.textaligncenter {
  text-align: center !important;
}

.barChart {
  background: white !important;
  border-radius: 15px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.waistModal {
  background: transparent !important;
  box-shadow: none !important;
  max-width: 700px !important;
  background: #1d1e4b !important;
  min-height: fit-content !important;
}
.waistModal img {
  text-align: center !important;
  border-radius: 15px;
  max-width: 100% !important;
  object-fit: contain;
}
.dressSizeModal {
  background: transparent !important;
  box-shadow: none !important;
  max-width: 700px !important;
  background: #fff !important;
  min-height: fit-content !important;
}
.closeModal {
  position: absolute !important;
  right: 65px !important;
  padding: 5px;
  border-radius: 50%;
  background: white;
  top: 15px !important;
  height: 25px;
  width: 25px;
}
.closeModal:hover {
  cursor: pointer !important;
  transition: all 0.3s;
  background: rgb(189, 212, 255);
}

.flexgrow {
  flex-grow: 1 !important;
}

.flexbasis200 {
  flex-basis: 200px !important;
}

.textunderline {
  text-decoration: underline !important;
}
.backgroundnone {
  background: none !important;
}
.waist-btns {
  background: #fbfbfb;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  box-shadow: 2px 2px 5px #dfdfdf;
  cursor: pointer;
  font-size: 14px !important;
  padding: 4px;
  transition: all 0.2s;
  width: 65px;
}
.waist-btns:hover {
  background: #d7ecff;
}
.mobileActiveStep {
  padding: 0px 20px;
  margin-bottom: 0px;
}
.results-info-text {
  margin: 20px;
}
.results-info-text h1 {
  margin-bottom: 10px;
  color: #00026e;
  margin-top: 20px;
}
.results-info-text li {
  font-size: 23px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.results-info-text a {
  font-size: 20px;
  margin-left: 25px;
}
.sunsafe-section {
  /* border: 1px solid #b9b9b9; */
  /* border-radius: 50px; */
  padding: 10px;
  text-align: center;
  width: 225px;
}
.sunsafe-section p {
  font-size: 21px;
  margin-bottom: 5px;
  margin-top: 4px;
}
.sunsafe-section img {
  height: 100px;
}
.ml-auto {
  margin-left: auto !important;
}
.fontweight500 {
  font-weight: 500 !important;
}
.fontweight600 {
  font-weight: 600 !important;
}
.dnone {
  display: none !important;
}

.fontItalic {
  font-style: italic !important;
}
table thead tr {
  background: var(--DarkBlue);
}
table thead th {
  color: white !important;
  font-size: 18px !important;
}
table td {
  font-size: 16px !important;
}
.demo-bubble {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 99;
}
.demo-bubble h5 {
  font-size: 20px;
  background: white;
  padding: 20px;
  margin: 0px;
  border-radius: 20px;
  line-height: 0.2;
  font-family: 'Foco CC Black';
  padding-top: 25px;
  color: #00026e;
  box-shadow: 2px 2px 5px #c1c1c1;
  font-weight: 700;
}
.importantText {
  font-weight: 800;
  font-family: 'Foco CC Black';
  color: #1e208f;
  font-size: 23px;
}

.zoom50 {
  zoom: 0.6 !important;
}

.noneSelected {
  opacity: 0;
}

.subtext-gray {
  font-size: 14px !important;
  color: gray !important;
  font-weight: 500 !important;
}

.opacity0 {
  opacity: 0 !important;
}

.toggleBtns {
  background: #082955;
  color: white;
  border-radius: 10px;
  font-size: 23px;
  width: 45px !important;
  padding: 6px !important;
  cursor: pointer;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.3s !important;
  border: none;
}
.toggleBtns:hover {
  transform: scale(1.05) !important;
  background: #0085dd !important;
}
.toggleBtns2:hover {
  background: #1a1e60 !important;
  color: white !important;
}
.waist-measurements-table {
  background: white;
  padding: 5px;
}
.waist-measurements-table td,
.waist-measurements-table th {
  padding: 3px;
  font-size: 15px !important;
}
/* .toggleOptions2 svg {
  background: #539ecf !important;
} */
.toggleBtns2 {
  background: white !important;
  color: #1a1e60 !important;
}
.toggleBackground img {
  height: 39px;
  object-fit: contain;
  margin-bottom: -5px;
}
.x-axis-wrap {
  word-wrap: break-word;
  max-width: 250px;
}
.toggleBackground {
  background: #fafafa;
  padding: 15px 15px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  width: 120px;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  transition: all 0.3s;
  flex-grow: 1;
  flex-basis: 100px;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 2px 2px 5px #d3d3d3;
  /* border: 1px solid #aed1e4; */
  border: 1px solid #dddddd;
  border-top-left-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 30px;
}
.toggleBackground h3 {
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.toggleBackground img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.drinksContainer {
  min-width: 150px !important;
}

li {
  list-style: none;
}
li {
  padding-left: 20px;
  margin-right: 20px;
  text-indent: -20px;
}

li::before {
  content: '• ';
  color: var(--L6Blue);
  padding-right: 10px;
}
li.MuiMenuItem-root::before {
  color: transparent !important;
}
.toggleBackground p {
  margin-bottom: 8px !important;
}
.toggleOptions2 > * .toggleBackground {
  border-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid #ebebeb;
}
.beachBackground2 {
  border-radius: 200px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.mobileViewStepper {
  flex-direction: column;
  text-align: center;
  gap: 5px;
}
.mobileViewStepper span {
  margin: auto;
}
.mobileViewStepper h3 {
  margin-top: 0px !important;
}

.l6title h3 {
  font-family: 'Foco CC Black';
  font-size: 23px;
}
.l6title {
  display: flex;
  gap: 5px;
  align-items: center;
}

.l6title.yellow h3 {
  color: var(--L6Yellow);
}
.l6title.yellow p {
  background: var(--L6Yellow);
}
.l6title.gray h3 {
  color: var(--L6Gray);
}
.l6title.gray p {
  background: var(--L6Gray);
}
.l6title.blue h3 {
  color: var(--L6Blue) !important;
}
.l6title.blue p {
  background: var(--L6Blue) !important;
}
.l6title.purple h3 {
  color: var(--L6Purple) !important;
}
.l6title.purple p {
  background: var(--L6Purple) !important;
}

.l6title.green h3 {
  color: var(--L6Green) !important;
}
.l6title.green p {
  background: var(--L6Green) !important;
}
.l6title.red h3 {
  color: var(--L6Red) !important;
}
.l6title.red p {
  background: var(--L6Red) !important;
}

.l6title p {
  width: 20px;
  height: 20px;
  border-radius: 2px 100%;
  transform: rotate(90deg);
  margin-top: 5px;
}
.headerSection {
  margin-bottom: -10px !important;
}
.headerSectionDiv p {
  height: 20px;
  width: 20px;
  border-radius: 3px 100%;
  transform: rotate(90deg);
  margin-top: 0px;
}

.popoverInformation {
  padding: 0px 10px;
  font-size: 15px;
}
.popoverInformation button {
  font-size: 14px !important;
}
.popoverInformation {
  width: fit-content !important;
  max-width: 350px !important;
  position: absolute;
  right: 10px;
  top: 300px;
  background: white;
  border-radius: 20px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.weightPetal {
  transform: rotate(45deg) !important;
  margin-top: -15px !important;
  margin-right: -10px;
  margin-left: -5px;
}
.slipSlopTitle {
  font-size: 30px;
  color: #00257b;
}
.select-inputs > * {
  height: 50px;
}

.select-inputs label {
  margin-top: -2px;
}

.fontBlack {
  font-family: 'Foco CC Black';
}
.select-inputs label {
  font-size: 15px;
}
.headerTitle {
  font-size: 25px;
}
.leftBorder {
  padding-left: 5px !important;
  border-left: 10px solid;
  border-color: #ffffff00;
}

.leftBorder.Personal {
  border-color: #009bdc;
}
.leftBorder.UV,
.leftBorder.UV_Park,
.leftBorder.UV_Beach {
  border-color: #e2bc2e;
}
.leftBorder.Smoking {
  border-color: #878888;
}
.leftBorder.Alcohol {
  border-color: #f05029;
}
.leftBorder.Nutrition {
  border-color: #73c04b;
}
.leftBorder.Weight {
  border-color: #199bd7;
}
.leftBorder.Physical {
  border-color: #199bd7;
}
.leftBorder.Screening {
  border-color: #7d489c;
}

.cursor-hover {
  cursor: pointer !important;
}

.visiblehidden {
  visibility: hidden !important;
  height: 0 !important;
}
.modalCloser {
  zoom: 1.3;
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
.scorecardTab {
  font-size: 24px !important;
  font-family: 'Foco CC Black' !important;
  transition: all 0.3s;
  max-height: 60px;
  padding-top: 20px !important;
}
.whitespacenowrap {
  white-space: nowrap;
}
.scorecardTab:hover {
  color: var(--DarkBlue);
}
.personal-subheaders {
  font-size: 18px;
}
.multiquestion-h3 {
  font-size: 20px;
}
.enterEmailResults {
  margin-top: 100px;
  margin-bottom: -50px;
  position: relative;
  z-index: 99;
  width: 90%;
  background: white;
  padding: 20px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: -270px;
  margin-top: 30px;
  box-shadow: 2px 2px 5px #535353;
  border: 1px solid #11509b;
}
.enterEmailResults h1 {
  font-size: 27px;
  color: var(--DarkBlue);
}
.autocomplete-items {
  font-size: 16px;
}
.autocomplete-textfield {
  font-size: 12px !important;
}

.disabled-overlay {
  opacity: 0.4;
  max-height: 400px;
  margin-top: -100px;
  overflow: hidden;
  pointer-events: none;
}

.donationSubtext {
  font-weight: 400;
  font-size: 19px;
}
/* MOBILE RESPONSIVENESS */
@media screen and (max-width: 1000px) {
  .headerBtns {
    zoom: 0.7;
  }
  .headerBtns button {
    font-size: 22px !important;
  }
}
/* Screens smaller than 850px */
@media screen and (max-width: 850px) {
  .parent-container {
    width: 100% !important;
    box-shadow: none !important;
  }
  .app-header .headerBtns {
    display: none;
  }
  .ccq-logo {
    height: 75px !important;
    margin-top: 5px;
  }
  .navbar-logo h1 {
    line-height: 0.8;
    margin-left: -10px;
  }
  .headerSection {
    flex-wrap: wrap;
    flex-direction: column-reverse !important;
  }
}

/* Screens smaller than 850px - mobile/tablets */
@media screen and (max-width: 850px) {
  .parent-container {
    margin-top: 20px !important;
  }
  .multiquestion-h3 {
    font-size: 17px;
  }
  .mobile5 {
    padding: 5px;
  }
  h1 {
    font-size: 22px !important;
  }
  button {
    font-size: 15px !important;
  }
  p {
    font-size: 15px !important;
  }
  .socialShareHeader {
    font-size: 19px !important;
  }
  h3 {
    font-size: 16px;
  }
  h2 {
    font-size: 19px;
  }
  .autocomplete-items {
    font-size: 15px;
  }
  .personal-subheaders {
    font-size: 17px;
    margin-top: 5px;
  }
  .personal-grid {
    margin-top: 0px !important;
  }
  .nutritionSection > * h2 {
    font-size: 20px !important;
    text-align: center;
  }
  .sliderSubtext {
    font-size: 15px !important;
    margin-bottom: -15px !important;
  }
  .slipSlopHeader {
    font-size: 20px !important;
  }
  .weightSubtext {
    font-size: 15px !important;
  }
  .atlas-external {
    display: none;
  }
  .cancer-atlas-link {
    font-size: 20px !important;
    padding: 20px 10px !important;
  }
  .toggleBackground {
    border-radius: 10px !important;
  }
  .image-carousel-container {
    zoom: 0.9;
  }
  .parent-container {
    margin: 0px !important;
    padding: 0px !important;
    border: 0 !important;
    width: 100% !important;
  }
  .toggleContainer {
    flex: 1;
    min-width: 100px;
    max-width: 120px;
  }
  .toggleBackground {
    zoom: 0.85;
    margin-bottom: -10px;
    padding: 10px !important;
    width: auto;
  }
  .toggleOptionUv {
    gap: 10px !important;
  }
  .accordianPanel {
    zoom: 0.8;
  }
  .headerTitle {
    font-size: 22px;
    margin-top: -5px !important;
  }
  .uvQuesResults {
    gap: 10px !important;
    zoom: 0.8;
    margin-top: 15px;
  }
  .drinksContainer {
    min-width: 130px !important;
  }
  .popoverInformation {
    display: none !important;
  }
  .scorecardTab {
    font-size: 19px !important;
  }
  .resourcesHeader {
    flex-wrap: wrap;
    gap: 0px;
    margin-bottom: 20px;
  }

  .donationSubtext {
    font-size: 14px;
  }

  .personal-inputs > * {
    height: 53px;
  }
  .slipSlopSlapContainer {
    zoom: 0.6;
  }
  .personal-inputs label {
    margin-top: -2px;
  }
  .likert-scale {
    justify-content: center;
  }
  .likert-scale label {
    max-width: 50px;
  }
  .rating-label-add span {
    font-size: 12px !important;
  }
  .btn-small {
    font-size: 14px !important;
  }
  .petalTitle {
    zoom: 0.7;
  }
  .viewmore-zoom {
    zoom: 0.8;
  }
  .lifestyle6-section {
    display: none;
    /* zoom: 0.8 */
  }
  .answer-btn,
  .link {
    font-size: 14px !important;
  }
  .toggleBackground img {
    height: 29px;
  }
  .headerQuestions {
    font-size: 18px !important;
  }
  .importantText {
    font-size: 17px !important;
  }
  .crcandlogo,
  .slipSlopIcons {
    display: none !important;
  }
  .crc-header {
    font-size: 33px !important;
  }
  .answer-btn-group {
    gap: 10px;
  }
  .subtext {
    font-size: 14px !important;
  }
  .leftBorder {
    border: 0px !important;
  }
  .parent-container {
    padding: 3px !important;
    padding-top: 10px !important;
    padding-bottom: 70px !important;
    width: 100% !important;
    border-radius: 0px;
    margin-bottom: 0 !important;
    margin-top: 20px !important;
  }

  .stepper-section {
    margin-top: 20px !important;
  }
  .slipSlopTitle {
    font-size: 22px !important;
  }
  .cc-title {
    font-size: 20px !important;
  }
  .bg-blob {
    display: none !important;
  }
  .cc-section li {
    font-size: 19px !important;
  }
  .nextBtn,
  .prevBtn {
    font-size: 17px !important;
    padding-top: 8px !important;
    font-family: 'Foco CC' !important;
  }
  li {
    padding-left: 0px;
    margin-right: -13px;
    text-indent: -11px;
  }
  li::before {
    content: '• ';
    color: var(--L6Blue);
    padding-right: 2px;
  }
  .MuiAutocomplete-root > * li::before {
    color: transparent !important;
  }
  .autocomplete * > li::before {
    color: transparent !important;
  }
  .MuiAutocomplete-listbox > li::before {
    color: transparent !important;
  }
  .landingpagemap button {
    font-size: 16px !important;
  }
  .learnMoreIcons img {
    height: 25px !important;
    width: 25px !important;
    object-fit: contain;
  }
  .toggleBtns {
    width: 35px !important;
    padding: 6px !important;
    height: 22px !important;
    font-size: 18px !important;
    margin-top: -8px;
    zoom: 0.9;
    margin-bottom: -4px;
  }
  .alcoholToggle {
    min-height: 36px;
    width: 50px !important;
  }

  .landingPageHeader {
    padding: 5px;
    margin: 0px !important;
  }
  .lifestyle6Main {
    justify-content: left;
  }
  .crc-header {
    display: none !important;
  }
  .results-container {
    padding: 10px !important;
  }
  .closeSlip {
    font-size: 19px !important;
  }
  .bmiHeaders {
    font-size: 19px !important;
  }
  .answerContainer {
    width: 10% !important;
    min-width: 130px !important;
    zoom: 0.9;
    /* margin: auto !important; */
  }
  .learnMoreInfo {
    margin-left: 0px !important;
  }
  .subtextBmi {
    font-size: 16px !important;
  }
  .physicalAnswer h5,
  .physicalAnswer p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 500px) {
  .likert-scale label {
    max-width: 25px;
  }
  .checkContainer {
    opacity: 0;
    max-width: 20px !important;
    margin-top: -10px;
    height: 0px !important;
  }
  .MuiSlider-root {
    pointer-events: none !important;
  }
  .MuiSlider-thumb {
    pointer-events: all !important;
  }
}

@media screen and (max-width: 400px) {
  .enterEmailResults p {
    font-size: 14px !important;
  }
  .likert-scale label span {
    font-size: 10px !important;
  }
}

/* Screens smaller than 400px */
@media screen and (max-width: 400px) {
  .drinksContainer {
    min-width: 110px !important;
  }
}
