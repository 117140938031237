@media screen and (max-width: 850px) {
  html {
    height: -webkit-fill-available;
    scroll-behavior: smooth;
  }

  /* Mobile CSS */
  .topMobileSection {
    height: calc(100vh - 40px);
    min-height: calc(100vh - 40px);
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    position: relative;
  }

  .mobile-bg {
    background: linear-gradient(
      45deg,
      #ffffff 0%,
      #f3f9ff 70%,
      #a9d7ff 100%
    ) !important;
    padding: 5px !important;
    margin: 0 !important;
    border-left: 0px;
  }

  .container-wave {
    background: linear-gradient(
      45deg,
      #ffffff 0%,
      #f3f9ff 70%,
      #a9d7ff 100%
    ) !important;
    /* background: white !important;  */
  }
  .ccq-logo-mobile {
    max-height: 80px !important;
    margin: 10px;
    background: white;
    border-radius: 15px;
    position: absolute;
    box-shadow:
      rgba(50, 50, 93, 0.13) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.158) 0px 8px 16px -8px;
  }
  .petalone {
    height: 100px;
    right: 20px;
    top: -11px;
    position: absolute;
  }
  .petaltwo {
    height: 150px;
    position: absolute;
    left: -11px;
    bottom: -35px;
  }
  .petalyellow {
    height: 165px;
    bottom: 15px;
    right: -50px;
    position: absolute;
  }
  .mobile-lp-btn {
    width: 90%;
    height: 50px;
    text-align: center;
    border: none !important;
    background: #082955 !important;
    color: white !important;
    border-radius: 5px !important;
    font-family: 'Foco CC Bd';
    padding-top: 5px;
    font-size: 20px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .begin-mobile {
    bottom: 50px;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
  }
  .subtext-mobile {
    font-size: 15px !important;
  }
  .landingpage-main {
    display: flex;
    margin: auto;
    text-align: center;
    height: calc(100vh - 40px);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .landingpage-main {
    padding: 20px;
  }

  .landingpage-main h1 {
    font-size: 45px !important;
    width: 240px;
    text-align: left;
    line-height: 1.1;
    color: #00026e;
  }

  .header-petal-mobile {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    zoom: 1.3;
  }
  .header-blob svg,
  .header-petal-mobile svg {
    transition: fill 0.3s ease;
    height: 70px;
    margin-top: 8px;
  }
  .icon-navbar-petal-mobile {
    position: absolute;
    margin-left: 3px;
    margin-top: 18px;
    transition: all 0.3s;
    animation: load-blob 0.5s ease-in-out;
  }
  .icon-navbar-petal-mobile.med {
    height: 34px;
    margin-top: 16px;
  }

  .icon-navbar-petal-mobile.lg {
    height: 36px;
    margin-top: 17px;
  }

  .icon-navbar-petal-mobile.sm {
    height: 28px;
    margin-top: 20px;
  }
  .landingpage-main p {
    font-size: 20px;
  }

  .landing-page-crc {
    font-size: 40px !important;
  }
  .mobileSel {
    margin-bottom: 0px;
    font-size: 25px !important;
    padding: 5px;
    color: #082955;
  }
  .hello-title {
    font-size: 25px !important;
    width: 100% !important;
    font-family: 'Foco CC';
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
    font-size: 27px;
  }

  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }

  #bottomMobileSection {
    height: 100%;
    margin-top: 50px;
    padding: 5px;
    position: relative;
  }
  .country-form {
    text-align: center !important;
  }
  /* .country-form > * input,.country-form > * label {
  font-size: 14px !important;
}
.country-form > * input,.country-form > * label {
  font-size: 14px !important;
} */
  .country-form > * h1 {
    font-size: 35px !important;
  }
  .continue-btn {
    z-index: 2;
    cursor: pointer;
  }

  /* .welcome-container{
background: white;
z-index: 2;
padding: 10px;
border-radius: 10px;
} */

  /* .mobileIconPetal > * .icon-navbar-petal.med {
  height: 34px !important;
  margin-top: 16px !important;
}

.mobileIconPetal > * .icon-navbar-petal.lg {
  height: 36px !important;
  margin-top: 17px !important;
}

.mobileIconPetal > * .icon-navbar-petal.sm {
  height: 28px !important;
  margin-top: 20px !important;
} */
}

@media screen and (max-width: 350px) {
  .mobileIconPetal {
    zoom: 0.7;
  }
  .welcome-container {
    zoom: 0.8;
  }
  #bottomMobileSection {
    zoom: 0.9;
  }
}
