.image-carousel-container {
  overflow: hidden;
  position: relative;
}
.image-carousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  transition: all 0.5s !important;
  padding-bottom: 20px !important;
  scroll-behavior: smooth !important;
}
/* .image-carousel::-webkit-scrollbar {
  display: none !important;
} */

.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 100px;
}
.carousel-controls button {
  height: 40px !important;
  width: 100px !important;
}
.card {
  min-width: 300px;
}

.image-carousel-item {
  height: 400px;
}
.image-carousel-item img {
  height: 220px;
  object-fit: cover;
  max-width: 201px;
  border-radius: 5px;
  overflow: hidden !important;
  object-position: top left;
}
.pdf-item {
  width: 250px;
  height: 300px;
  margin-right: 20px;
  max-width: 200px !important;
  border: none;
  border-radius: 15px;
  box-shadow: 2px 2px 5px #b3b3b3;
  background: white !important;
  transition: all 0.3s !important;
}
.pdf-item:hover {
  cursor: pointer;
  background: rgb(239, 245, 255) !important;
  /* text-decoration: underline !important; */
}
.pdf-arrow-right {
  margin-top: -3px;
}
.pdf-item:hover .pdf-arrow-right {
  transition: all 0.3s !important;
  transform: translateX(5px);
}

.image-carousel {
  display: flex;
  gap: 5px;
}
.pdf-btn {
  color: #151e62;
  font-family: 'Foco CC Bd' !important;
  font-size: 16px !important;
  margin-top: 3px !important;
  display: flex;
  gap: 5px;
  align-items: center;
}

.pdf-item img {
  height: 255px;
  object-fit: cover;
  object-position: top left;
  max-width: 200px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.pdf-modal-viewer {
  width: 80vw !important;
  max-width: 80vw !important;
  height: 90vh !important;
}
.modalStylesPdf {
  background: white;
  padding: 30px;
  padding-top: 10px;
  border-radius: 15px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  outline: none !important;
  width: 80vw !important;
  max-width: 80vw !important;
  height: 95vh !important;
  margin-top: 20px;
}

.modalClose {
  margin-left: auto !important;
}

.iframe-pdf {
  box-shadow: 2px 2px 5px #c1c1c1;
  border: none;
  border-radius: 3px;
  zoom: 4;
}

@media screen and (max-width: 850px) {
.modalStylesPdf{
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100vh !important;
  padding: 5px !important;
}
}