.stepper-section {
  z-index: 10;
  margin-top: 30px !important;
  font-weight: 600 !important;
  zoom: 1.05;
  margin-bottom: -10px !important;
}
.stepper-section2 {
  margin-top: 10px !important;
  padding-bottom: 15px;
  margin-bottom: 30px !important;
}
.MuiStepLabel-alternativeLabel {
  font-weight: 700 !important;
  font-size: 16px !important;
  font-family: 'Foco CC' !important;
}
.Mui-completed {
  cursor: pointer !important;
}
.Mui-completed div:hover {
  box-shadow: 2px 2px 4px #fff9e2;
}
.mobileStepper {
  background: transparent !important;
  margin-top: -15px !important;
}

.mobileStepper span {
  width: 90%;
  margin: auto;
  height: 10px;
  border-radius: 5px;
  /* background: #68acff !important; */
}

.stepperMobileText {
  width: 85%;
  margin: auto;
  margin-bottom: 5px;
  font-size: 13px
}

.demo-stepper span{
  cursor:pointer !important;
}